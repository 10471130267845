.scroll-top-button-container {
    display: flex;
    justify-content: center;
}

.scroll-top-button {
    transition: .25s;
    opacity: 1;
    position: fixed;
    z-index: 12;
    transform: translateY(12.5%);
    box-shadow: 0px 2px 4px #35353560;
    border-radius: var(--button-border-radius);
}

.scroll-top-button.hidden {
    opacity: 0;
    z-index: -1;
}
