
.nav {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: var(--map-bottom-offset);
    padding: 0rem 1rem 2rem;
    display: flex;
    z-index: 50;

    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.nav .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;

    display: flex;
    flex-direction: column;
    color: var(--color-text-light);

    text-decoration: none;
}

.nav.desktop {
    position: relative;
    display: flex;

    left: 0;
    top: 0;
    width: var(--desktop-nav-width);
    padding: 0rem 0;
    height: 100vh;

    flex-direction: column;
}

.nav.desktop .nav-item {
    flex-grow: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: var(--desktop-nav-width);
}

.nav.desktop .nav-item.nav-item.active {
    background-color: var(--color-grey-150);
}


.nav-icon {
    color: var(--color-text-light);
}
.nav .nav-item.btn small {
    color: var(--color-text-light);
}

.nav-item.active .nav-icon {
    color: var(--color-text);
}
.nav .nav-item.btn.active small {
    color: var(--color-text);
}