.load-more-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
}

.load-more-button {
    border-radius: .75rem;
}
