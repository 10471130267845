.summit-name {
    display: flex;
    align-items: center;
}

.summit-name h5, .summit-name h3 {
    margin-right: .5rem;
    margin-bottom: .125rem;
}
.checkmark {
    color: var(--success);
}

.heart {
    color: var(--color-red);
}

.alternative-names {
    color: var(--color-text-light);
    margin-bottom: .25rem;
}