
.bagging-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bagged-date-picker {
    display: inline-block;
    margin: .25rem .5rem 0;

    width: auto;
}

.bag-button {
    border-radius: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bag-button svg {
    margin-right: .25rem;
}

.bagging-card {
    margin-bottom: 1.5rem;
}
