.search-input-group {
    margin-bottom: 0.75rem;
    border-radius: .75rem;
    transition: .25s;
}

.search-input-group:focus-within {
    /* box-shadow: 0px 0px 2px #aaa; */
}

.search-icon {
    background-color: white;
    color: #6c757d;
    border-radius: .75rem;
    border-width: 1px;
}

.search-icon.left {
    border-right-width: 0;
    padding-right: 0;
}

.search-icon.right {
    border-left-width: 1px;
    /* padding-left: 0; */
    cursor: pointer;
}

.search-icon.right:hover {
    background-color: var(--color-grey-100);
}

.search-input {
    border-left: 0;
    border-radius: .75rem;
    border-width: 1px;
}

.search-input:not(:last-child) {
    border-right: 0;
}

.search-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
}