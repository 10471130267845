
.forecast-row {
    display: flex;
    justify-content: space-around;
    padding: .5rem .75rem;
}

.forecast-row > * {
    flex-grow: 1;
    flex-basis: 0;
}

.weather-day {
    display: flex;
    flex-direction: column;
    align-items: center;
}

span.hot {
    color: #be3838;
}

span.cold {
    color: #4c90c8;
}
