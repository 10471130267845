.sort-controls-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sort-controls-toggle {
    padding: .25rem .5rem;
    border-radius: .75rem;
    display: flex;
    align-items: center;
}

.sorting-option-row {
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
}

.sorting-option-row.list-group-item.active {
    background-color: rgb(238, 238, 238);
    border-color: #e2e2e2;
}

.sorting-options-list {
    margin-top: .5rem;
    border-radius: var(--button-border-radius);
}
