
.panel-wrapper {
    position: relative;
    min-width: var(--side-panel-width);
    width: var(--side-panel-width);
    min-height: 100vh;
    overflow: visible;
    z-index: 10;
    background-color: white;
    box-shadow: 0px 0px 10px #888;
    transition: .2s;
    left: 0px;
}

.panel-wrapper:not(.open) {
    left: calc(var(--desktop-nav-width) - var(--side-panel-width));
    position: absolute; /* remove from flow */
}

.panel-wrapper.hidden {
    left: calc(-6rem - var(--side-panel-width));
    position: absolute;
}

.panel-content-wrapper {
    overflow: hidden;
    min-width: var(--side-panel-width);
    width: var(--side-panel-width);
}

.panel {
    position: relative;
    min-width: var(--side-panel-width);
    width: var(--side-panel-width);
    max-height: 100vh;
    padding: 0 0rem 1rem;
    z-index: 10;
    overflow-y: auto;
    scrollbar-width: thin;
}

/* Chrome, Edge, Safari */
.panel::-webkit-scrollbar {
    width: 6px;
}
.panel::-webkit-scrollbar-track {
    background: #e0e0e0;
}
.panel::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
}
.panel::-webkit-scrollbar-thumb:hover {
    background-color: #9e9e9e;
}

/*
TODO: make this work so content does not resize when scrollbar appears
.panel:hover {
    overflow-y: auto;
} */

.panel-body {
    
}

.panel-body:not(.flush) {
    padding: 0rem 1rem;
}

.sticky-header {
    min-height: fit-content;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    top: 0px;
    z-index: 10;
    background-color: white;
    padding: 1rem 1rem .75rem;
    border-bottom: 1px solid #eee;
}

.panel-toggle {
    position: absolute;
    top: 10px;
    right: -1.5rem;
    width: 1.5rem;
    height: 3rem;
    
    padding: 0;
    border: none;
    border-radius: 0;
    border-left: 1px solid #eee;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    outline: none;
    z-index: 9;
    align-items: center;
    justify-content: center;
    
    background-color: white;
    box-shadow: 0 0 0 2px rgba(0,0,0,.1);
}

.panel-toggle-icon > path {
    stroke-width: 66;
}
