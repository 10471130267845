.map {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
}

.map.mobile a.mapboxgl-ctrl-logo {
    margin: 0 0 calc(6px + var(--map-bottom-offset)) -4px;
}

.map.mobile .mapboxgl-ctrl-bottom-right {
    bottom: calc(10px + var(--map-bottom-offset));
}

/* Fix for bottom right attribution on mobile having min-height: 20px */
.map.mobile .mapboxgl-ctrl-attrib.mapboxgl-compact {
    min-height: 24px;  
}

.driving-start-point-popup {
    font-family: 'Plus Jakarta Sans', var(--system-font-stack);
}
