

.profile-content {
    padding-top: 1rem;
}

.bagged-counts {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5rem;
}

.bagged-counts .count-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-grey-100);
    padding: .5rem;
    border-radius: 1rem;
    margin-right: .75rem;
    flex: 1 1 0px;
}

.count-detail:last-of-type {
    margin-right: 0;
}

.bagged-counts .count-detail big {
    font-size: 28px;
    font-weight: bold;
}

.bagged-counts .count-detail .total {
    font-size: small;
    font-weight: 400;
    color: var(--color-text-light)
}

.profile-content .list-group {
    border-radius: var(--button-border-radius);
    /* border-radius: 0; */
    /* border-top: 1px solid rgba(0, 0, 0, .125); */
    /* border-bottom: 1px solid rgba(0, 0, 0, .125); */
    /* margin: 1rem -1rem; */
}

.profile-content .more-button {
    margin: .75rem 0 1.5rem;
}

.profile-content .btn {
    /* border-bottom-left-radius: var(--button-border-radius); */
    /* border-bottom-right-radius: var(--button-border-radius); */
    border-radius: var(--button-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-content .btn svg {
    margin-left: .5rem;
}

.profile-content > h5 {
    margin-bottom: .75rem;
}