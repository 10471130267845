
.filter-controls {
    padding: .5rem 1.5rem 0;
}

.filter-slider-container {
    padding: 0rem;
}

.filter-slider {
    margin-top: .25rem;
    margin-bottom: 2rem;
}

/* .filter-slider {
    margin-bottom: 1rem;
} */

.rc-slider-handle {
    width: 22px;
    height: 22px;
    margin-top: -9px;
    border-width: 3px;
}