
.walk-detail-header {
    display: flex;
    align-items: center;
}

.walk-detail-header .map-marker {
    margin: 0 1.25rem 0 .25rem;
    transform: translateY(.75rem) translateX(.25rem);
}

.walk-detail-header .map-marker span {
    box-shadow: 0px 0px 3px #99999999;
}

.walk-detail .walk-stats {
    display: flex;
    justify-content: space-around;
    font-weight: 500;
    padding: 0.5rem 0;
}

.walk-detail .walk-stats .stat {
    flex-grow: 1;
    flex-basis: 0px;
    padding: 0 .25rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.walk-detail .progress-bar-stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.walk-detail .progress-bar-stats .stat {
    flex-grow: 1;
    padding: .25rem .5rem .5rem;
}

.walk-detail .progress-bar-stats .stat .progress {
    margin-top: .125rem;
}

.walk-detail .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--button-border-radius);
}

.walk-detail .map-marker {
    margin-right: .5rem;
}


.walk-detail tr td {
    border-right-width: 0px;
    border-left-width: 0px;
    padding-left: .5rem;
}

.walk-detail td .table-badge {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    height: 1.5rem;
    font-size: 1rem;
}

.bg-grade-1 {
    background: #86993a;
}
.bg-grade-2 {
    background: #7b7e33;
}
.bg-grade-3 {
    background: #71632c;
}
.bg-grade-4 {
    background: #664825;
}
.bg-grade-5 {
    background: #773e2c;
}

.bg-bog-1 {
    background: #34a717;
}
.bg-bog-2 {
    background: #2c9e45;
}
.bg-bog-3 {
    background: #288f7e;
}
.bg-bog-4 {
    background: #247a8f;
}
.bg-bog-5 {
    background: #1a619e;
}