.badge-row {
    display: flex;
    margin-bottom: .25rem;
    margin-top: .25rem;
    flex-wrap: wrap;
}

.summit-badge {
    display: inline-flex;
    height: 1.35rem;
    font-size: .875rem;
    align-items: center;
    flex-direction: row;
    margin: 0.125rem 0.25rem 0.125rem 0;
    font-weight: 600;
}

.summit-badge.no-margin {
    margin: 0;
}

.summit-badge svg {
    margin-right: 0.2rem;
}

.badge-munro {
    background-color: var(--color-munro);
    color: white;
}
.badge-corbett {
    background-color: var(--color-corbett);
    color: white;
}
.badge-graham {
    background-color: var(--color-graham);
    color: white;
}
.badge-other {
    background-color: var(--color-other);
    color: white;
}
