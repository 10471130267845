@font-face {
    font-family: 'DIN Pro Medium';
    src: url(./fonts/DIN\ Pro\ Medium\ 500.otf) format('opentype');
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakarta_Sans-2.5/webfonts/PlusJakartaSans-Regular.woff') format('woff');
    font-weight: lighter;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakarta_Sans-2.5/webfonts/PlusJakartaSans-Medium.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakarta_Sans-2.5/webfonts/PlusJakartaSans-Bold.woff') format('woff');
    font-weight: bold;
}

* {
    box-sizing: border-box;
}

:root {
    --color-munro: #bd3742;
    --color-corbett: #345995;
    --color-graham: #e2a413;
    --color-other: #d8973c;

    --color-text: #444444;
    --color-text-light: #929292;
    --color-grey-100: #f5f5f5;
    --color-grey-150: #f3f3f3;
    --color-grey-200: #ececec;

    --color-red: #c72e2e;

    --side-panel-width: 450px;

    --map-bottom-offset: 4.75rem;
    --desktop-nav-width: calc(4.5rem - 3px);

    --system-font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    --card-shadow: 0px 2px 10px #ccc;
    --card-border-radius: 1rem;
    --button-border-radius: .75rem;
}

html,
body {
    width: 100vw;
    height: 100vh;
    font-family: 'Plus Jakarta Sans', var(--system-font-stack);
    font-display: swap;
    position: fixed;
    overflow: hidden;
    background-color: #ddd;
}

body > .mainwrapper {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flex-row {
    display: flex;
}

.full-page {
    height: 100vh;
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
    font-family: 'Plus Jakarta Sans', var(--system-font-stack);
}

h1, h2, h3, .h1, .h2, .h3 {
    font-weight: 600;
}

h1, h2, .h1, .h2 {
    color: #2d2d2d;
}

h3, h4, h5 {
    color: #4d4d4d;
}

div, p, span, td {
    color: var(--color-text);
}

/* Bootstrap Customisation */

:root {
    --primary: hsl(287, 28%, 58%);;
    --primary-dark-tint: hsl(287, 28%, 48%);
    /* --secondary: hsl(337, 7%, 46%);
    --success: hsl(147, 57%, 40%);
    --success-dark-tint: hsl(147, 57%, 30%);
    --info: hsl(203, 64%, 53%); */
    /* --warning: #ffc107; */
    /* --danger: #FF5714; */
    /* --danger-dark-tint: hsl(17, 100%, 44%); */
}

.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--primary-dark-tint);
    border-color: var(--primary-dark-tint);
}
.btn-outline-primary:not(.active):not(:hover) {
    color: var(--primary);
}

/* .btn-danger {
    background-color: var(--danger);
    border-color: var(--danger);
}
.btn-outline-danger {
    color: var(--danger);
}

.btn-info {
    background-color: var(--info);
    border-color: var(--info);
}
.btn-outline-info {
    color: var(--info);
}

.btn-success {
    background-color: var(--success);
    border-color: var(--success);
}
.btn-outline-success {
    color: var(--success);
} */


.btn-outline-info:not(.active):not(:hover),
.btn-outline-secondary:not(.active):not(:hover),
.btn-outline-primary:not(.active):not(:hover),
.btn-outline-danger:not(.active):not(:hover),
.btn-outline-success:not(.active):not(:hover),
.btn-outline-warning:not(.active):not(:hover),
.btn-munro:not(.active),
.btn-corbett:not(.active),
.btn-graham:not(.active),
.btn-fill-toggle:not(.active) {
    border-color: var(--color-grey-150);
    background-color: var(--color-grey-100);
}

.btn-outline-info:not(.active):hover,
.btn-outline-secondary:not(.active):hover,
.btn-outline-primary:not(.active):hover,
.btn-outline-danger:not(.active):hover,
.btn-outline-success:not(.active):hover,
.btn-outline-warning:not(.active):hover {
    background-color: var(--color-grey-200);
    border-color: var(--color-grey-200);
}

.btn-outline-info:not(.active):hover { color: var(--info); }
.btn-outline-secondary:not(.active):hover { color: var(--secondary); }
.btn-outline-primary:not(.active):hover { color: var(--primary); }
.btn-outline-danger:not(.active):hover { color: var(--danger); }
.btn-outline-success:not(.active):hover { color: var(--success); }
.btn-outline-warning:not(.active):hover { color: var(--warning); }

.btn {
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: bold;
}

.spinner-border {
    margin: 1rem;
}

.card {
    border-radius: var(--card-border-radius);
    margin-top: 1.5rem;
    border-width: 0px;
    box-shadow: var(--card-shadow);
    overflow: hidden;
}

.card-header {
    font-weight: 600;
}

.alert {
    border-radius: var(--card-border-radius);
}