

.share-button {
    border-radius: var(--button-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-button svg {
    margin-right: .25rem;
}