
.summit-filters {
    display: flex;
    /* justify-content: center; */
    font-family: 'Plus Jakarta Sans', var(--system-font-stack);
}

.btn.summit-toggle {
    transition: 0.25s;
}

.btn-munro, .btn-munro.focus {
    border-color: var(--color-munro);
    background-color: var(--color-grey-100);
    color: var(--color-munro);
    /* color: var(--color-text); */
}
.btn-corbett, .btn-corbett.focus {
    border-color: var(--color-corbett);
    background-color: var(--color-grey-100);
    color: var(--color-corbett);
    /* color: var(--color-text); */
}
.btn-graham, .btn-graham.focus {
    border-color: var(--color-graham);
    background-color: var(--color-grey-100);
    color: var(--color-graham);
    /* color: var(--color-text); */
}

.btn.btn-munro:hover {
    color: var(--color-munro);
}
.btn.btn-corbett:hover {
    color: var(--color-corbett);
}
.btn.btn-graham:hover {
    color: var(--color-graham);
}

.btn-munro.active {
    background-color: var(--color-munro);
    color: white;
}
.btn-corbett.active {
    background-color: var(--color-corbett);
    color: white;
}
.btn-graham.active {
    background-color: var(--color-graham);
    color: white;
}

.btn.btn-munro.active:hover,
.btn.btn-corbett.active:hover,
.btn.btn-graham.active:hover {
    color: white;
}