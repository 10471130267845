
.map-marker {width:0; height:0;}

.map-marker > span {
    display:flex;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    color:#fff;
    border:solid 2px;
    height: 30px;
    width: 30px;
    border-radius: 0 70% 70%;
    box-shadow: -3px -3px 3px #99999999;
    transform-origin:0 0;
    transform: rotateZ(-135deg);
    font-family: 'Plus Jakarta Sans', var(--system-font-stack);
    font-size: .75rem;
}

.map-marker > span > div {
    border-radius: 70%;
    height: 40%;
    width: 40%;
    background-color: white;
}

.map-marker > span > b {transform: rotateZ(135deg)}

.map-marker.circle {
    width: auto;
    height: auto;
}

.map-marker.circle > span {
    transform: none;
    border-radius: 70%;
    box-shadow: none;
}

.map-marker.circle > span > b {
    transform: none;
}