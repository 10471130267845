
.toggle-buttons .btn-group {
    display: block !important; 
    font-family: 'Plus Jakarta Sans', var(--system-font-stack);
}

.btn.toggle {
    transition: 0.25s;
    text-transform: capitalize;
    border-radius: .75rem;
}

.btn-group > label.btn.toggle {
    font-weight: 600;
    border-width: 1px;
    font-size: .9rem;
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
}
.btn-group > .btn:not(:first-child) {
    border-radius: .75rem;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: .75rem;
}

.btn-fill-toggle, .btn-fill-toggle.focus {
    border-color: var(--secondary);
    background-color: var(--color-grey-100);
    color: var(--secondary);
}

.btn.btn-fill-toggle:hover {
    color: var(--secondary);
}

.btn-fill-toggle.active {
    background-color: var(--secondary);
    color: white;
}

.btn.btn-fill-toggle.active:hover {
    color: white;
}