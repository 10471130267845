
.routes-list {
    border-radius: .75rem;
    /* margin: -1rem 0; */
}

.quick-actions {
    display: flex;
    margin-top: 1rem;
}

.quick-actions .btn:not(:last-of-type) {
    margin-right: .75rem;
}

.no-routes {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

.no-routes .no-routes-icon {
    height: 2.5rem;
    width: 2.5rem;
}

.back-to-search-button {    
    padding-left: .5rem;
    padding-right: .5rem;
    margin-top: -.75rem;
    margin-left: -.5rem;

    display: flex;
    align-items: center;
}