
.modal-content {
    border-radius: .75rem;
}

.landing-content {
    padding: .75rem;
}

.landing-content .btn {
    border-radius: var(--button-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: .675rem;
    padding-bottom: .675rem;
}

.landing-content .btn svg {
    margin-left: .25rem;
}

.landing-content .mountain-classes {
    margin: 1rem 0 1.5rem;
}