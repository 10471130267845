
.cupertino-pane {
    overflow-y: visible;
}

.pane {
    border-radius: .75rem !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, .24) !important;
    bottom: 0 !important;
}

.pane .close-button {
    top: 1rem !important;
    right: 1rem !important;
    line-height: 1rem;
}

.sheet-body {
    display: block;
    padding-bottom: 5rem;
}

.title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-row h1 {
    font-size: 2rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.body-header {
    min-height: fit-content;
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    top: 0px;
    z-index: 11;
    background-color: white;
    padding: 0 1rem;
    padding-bottom: .25rem;
}

.body-header-border {
    border-bottom: 1px solid #eee;
}

.body-subheader {
    padding: 0 1rem;
}

.body-open {
    opacity: 1;
    transition: 0.25s;
    flex-grow: 1;
    overflow: visible;
    padding: 0rem 1rem calc(2.5rem + var(--map-bottom-offset));
}

.body-closed {
    opacity: 0;
    transition: 0.25s;
    padding: 0rem 1rem 2rem;
}
